import { Link } from "gatsby";
import * as React from "react"
import '../styles/main.scss'
import Layout from "../components/layouts/layout";
import { SeoC } from "../components/utils/seo";
import { graphql } from "gatsby";
// import { Parallax } from "react-scroll-parallax";
// import YouTubeEmbed from "../components/video/youtube"
import VideoComponent from '../components/video/videoComponent'
import Logo from '../components/utils/logo'
import Back from '../images/back.svg'
import Portfolio from "../components/portfolio/portfolio";

// markup
const WorkTemplate = ({data}) => {

  const singleWork = data.wpReel

  const posterImage = singleWork.featuredImage.node.localFile.childImageSharp.fixed.src

  const moreWorks = data.allWpReel.edges

  React.useEffect(() => {

    // document.getElementById("vjs_video_3").classList.add('vjs-theme-forest');
    
  }, [])
  
  return (
    <Layout>
      {singleWork.slug && (   
      <div className="work-page">
          <div className="container">
        <div className="work-header">
            <span className="back"><Link to={`/`}><img src={Back} alt="Go back"/></Link></span>
            <span className="logo"></span>
        </div>   
            </div>
            <div className="the-video">
              <VideoComponent video={singleWork.reels.video.publicUrl} posterImg={posterImage}/>
              {/* <YouTubeEmbed title={singleWork.title} video={singleWork.reels.youtube}/> */}
            </div>
            <div className="container">
            <div className="video-info">
              <h2>{singleWork.title}</h2>
              <ul className="meta">
                {singleWork.reels.client && (
                  <li><span>Client </span>{singleWork.reels.client}</li>
                )}
                {singleWork.reels.agency && (
                  <li><span>Agency </span>{singleWork.reels.agency}</li>
                )}
                {singleWork.reels.director && (
                  <li><span>Director </span>{singleWork.reels.director}</li>
                )}
              </ul>
            </div>
          </div>
          <div className="contact-box">
            <Logo />
            <a href="mailto:contact@ifthi.com?subject='Ads/Jingles Enquiry'">Get in touch.</a>
          </div>
          <Portfolio data={moreWorks} />
      </div>
      )}
    </Layout>
  )
}

export const Head = ({data}) => (
  <SeoC title={data.wpReel.title + ` - ` + data.wpReel.reels.client} />
)

export default WorkTemplate

export const workQuery = graphql`
  query WorkByPath($id: String) {
    wpReel(id: { eq: $id }) {
      id
      title
      slug
      content
      reels {
        agency
        client
        director
        youtube
        video {
          publicUrl
          mediaItemUrl
        }
      }
      featuredImage {
          node {
              publicUrl
              localFile {
                childImageSharp {
                  fixed(toFormat: JPG) {
                    aspectRatio
                    src
                  }
              }
            }
          }
        }
      
    }
    allWpReel(filter: {id: {ne: $id}}, sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
        link
        title
        date
        content
        databaseId
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        reels {
        agency
        client
        director
      }
        }
      }
  }
    
  }
`;

